/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import Next from 'img/icon/next.inline.svg'
import ButtonShell from './ButtonShell'

// Icons

const StyledButton = styled.div`
  background-color: ${props => props.theme.color.contrast};
  display: inline-block;
  border-radius: 30px;
  
  & > a, & > button {
    font-weight: ${props => props.theme.font.weight.l};
    color: ${props => props.theme.color.text.light};
    font-style: italic;
    padding: 10px 25px;
    width: 100%;
    height: 100%;
    display: block;

    & > svg {
      margin-left: 15px;

      @media (min-width: 992px) {
        height: 25px;
        width: 25px;
      }

      @media (max-width: 991px) {
        height: 20px;
        width: 20px;
      }
    }

    @media (min-width: 992px) {
      font-size: ${props => props.small ? props.theme.font.size.m : props.theme.font.size.xm};
      padding: 12px 35px;
    }

    @media (max-width: 991px) {
      font-size: ${props => props.small ? props.theme.font.size.m : props.theme.font.size.m};
      padding: 10px 25px;
    }
  }

  &:hover {
    text-decoration: underline;
  }
`

const ButtonAlt = ({ isAnchor, isCustom, to, children, className, type }) => {
  let icon = null

  switch (type) {
    case 'next':
      icon = <Next />
      break;
  
    default:
      break;
  }

  return (
    <StyledButton className={className}>
      {isCustom ? children : (
        <ButtonShell to={to} isAnchor={isAnchor}>
          {children}
          {icon}
        </ButtonShell>
      )}
    </StyledButton>
  )
}

export default ButtonAlt