/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import ParseContent from 'components/shared/ParseContent'

// Libraries
import parse from 'html-react-parser'
import Typewriter from 'typewriter-effect'

// Language
import { getLanguage } from 'services/language'

// Elements
import BlockDefault from 'components/elements/BlockDefault'
import ButtonAlt from 'components/elements/ButtonAlt'
import ButtonDefault from 'components/elements/ButtonDefault'
import Plaatjie from '@ubo/plaatjie'

// Components
import RatingAlt from 'components/Reviews/RatingAlt'

// Icons
import Search from 'img/icon/search.inline.svg'

const NationsSeeker = ({ fields, hideButton, showSirelo, showAll }) => {
  const language = getLanguage()

  const {
    allWordpressPage: nations
  } = useStaticQuery(graphql`
    {
      allWordpressPage(filter: {parent_element: {wordpress_id: {in: [118, 133]}}}) {
        edges {
          node {
            wordpress_id
            title
            path
            wpml_current_locale

            acf {
              banner {
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 400)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const [searchValue, setSearchValue] = useState('')

  let nationsContent = nations.edges.filter(({ node: { wpml_current_locale: locale } }) => locale === language)
  let searchOptions = nationsContent

  // if(searchOptions.length > 4 && showAll) {
  //   searchOptions = searchOptions.filter(({ node }) => fields.highlighted.indexOf(node.wordpress_id) === -1)
  // }

  if(!showAll){
    searchOptions = searchOptions.splice(0,3)
  }

  const removeDiacritics = str => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').trim()

  searchOptions = searchOptions.map(({ node: { title } }) => title)

  if(searchValue !== '') {
    nationsContent = nationsContent.filter(({ node: { title } }) => removeDiacritics(title.toLowerCase()).indexOf(removeDiacritics(searchValue.toLowerCase())) !== -1)
  } else if(fields.highlighted) {
    nationsContent = nationsContent.filter(({ node: { wordpress_id: wpId } }) => fields.highlighted.indexOf(wpId) !== -1)
  }

  if(!showAll){
    nationsContent = nationsContent.splice(0, 3)
  }

  return (
    <div>
      <div className="row">
        <div className="col-lg-7 col-xl-8 d-flex align-items-center">
          <SearchBar
            searchOptions={searchOptions}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            fields={fields}
          />
        </div>
        {showSirelo && (
          <div className="col-lg-5 col-xl-4 d-flex align-items-center justify-content-lg-start justify-content-center">
             <RatingAlt noTitle className="pb-lg-0 pb-4" />
          </div>
        )}
      </div>

      <div className="mt-5">
        {parse(fields.description)}
      </div>

      <div className="row mt-4">
        {nationsContent.map(({ node: { wordpress_id: wpId, title, path, acf } }) => (
          <Nation
            key={wpId}
            title={title}
            path={path}
            image={acf.banner.image}
          />
        ))}

        {nationsContent.length === 0 && (
          <ParseContent className="col-12 py-5" content={fields.no_results_description} />
        )}
      </div>

      {!hideButton && (
        <div className="mt-5 text-center">
          <ButtonDefault to={fields.button.url} type="next">
            {fields.button.title}
          </ButtonDefault>
        </div>
      )}
    </div>
  )
}

const StyledSearchBar = styled(BlockDefault)`
  padding: 15px 35px !important;
  width: 100%;

  & > h2 {
    font-weight: ${props => props.theme.font.weight.l};
    color: ${props => props.theme.color.text.contrast};
    margin: 0;

    @media (min-width: 992px) {
      font-size: ${props => props.theme.font.size.xl};  
    }

    @media (max-width: 991px) {
      font-size: ${props => props.theme.font.size.l};  
    }
  }
`

const InputButton = styled.div`
  border-bottom: 1px solid ${props => props.theme.color.text.main};

  &:hover {
    cursor: text;
  }
  
  @media (min-width: 767px) {
    width: 35%;
  }
`

const InputSearch = styled.input`
  border: none;
  border-bottom: 1px solid ${props => props.theme.color.text.main};
  width: 35%;
  outline: none;
`

const Icon = styled(Search)`
  height: 30px;
  width: 30px;
`

const SearchBar = ({ searchOptions, searchValue, setSearchValue, fields }) => {
  const [isTyping, setIsTyping] = useState(false)

  const options = searchOptions.map(a => ({sort: Math.random(), value: a}))
    .sort((a, b) => a.sort - b.sort)
    .map(a => a.value)

  let inputRef = null

  useEffect(() => {
    if(isTyping && searchValue === '') {
      inputRef.focus()
    }
  }, [isTyping])


  return (
    <StyledSearchBar className="d-md-flex justify-content-between align-items-center">
      <h2 className="mr-3">
        {fields.title}
      </h2>

      {!isTyping && (
        <InputButton
          role="button"
          onClick={() => {
            setIsTyping(true)
          }}
          className="mt-3 mt-md-0"
        >
          <Typewriter
            options={{
              strings: options,
              autoStart: true,
              loop: true,
            }}
          />
        </InputButton>
      )}

      {isTyping && (
        <InputSearch
          ref={input => { inputRef = input }}
          type="text"
          value={searchValue}
          onChange={event => {
            setSearchValue(event.target.value)

            // if(event.target.value === '') {
            //   setIsTyping(false)
            // }
          }}
          className="mt-3 mt-md-0"
        />
      )}

      <Icon className="d-none d-md-inline-block" />
    </StyledSearchBar>
  )
}

const Thumbnail = styled(Plaatjie)`
  border-radius: 15px;
  overflow: hidden;
  z-index: 1;
  height: 230px;
  display: block !important;
`

const Content = styled.div`
  z-index: 2;
`

const Nation = ({ title, path, image }) => (
  <div className="col-md-4 mb-5">
    <Thumbnail image={image} alt={title} />
    <Content className="text-center mt-n4 position-relative">
      <ButtonAlt to={path} type="next">
        {title}
      </ButtonAlt>
    </Content>
  </div>
)

export default NationsSeeker
